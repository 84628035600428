import { css } from '@emotion/react'
import type { Theme } from '../theme'

export const grid = (theme: Theme) => css`
  .row {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -1rem;
  }
  .col,
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding: 1rem;
  }
  .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1 {
    flex-basis: 8.3333333%;
    max-width: 8.3333333%;
  }
  .col-2 {
    flex-basis: 16.6666666%;
    max-width: 16.6666666%;
  }
  .col-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-4 {
    flex-basis: 33.3333333%;
    max-width: 33.3333333%;
  }
  .col-5 {
    flex-basis: 41.6666666%;
    max-width: 41.6666666%;
  }
  .col-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-7 {
    flex-basis: 58.3333333%;
    max-width: 58.3333333%;
  }
  .col-8 {
    flex-basis: 66.6666666%;
    max-width: 66.6666666%;
  }
  .col-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-10 {
    flex-basis: 83.3333333%;
    max-width: 83.3333333%;
  }
  .col-11 {
    flex-basis: 91.6666666%;
    max-width: 91.6666666%;
  }
  .col-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  @media (max-width: ${theme.maxWidthMd}) {
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11 {
      flex-basis: 50%;
      max-width: 50%;
    }
  }
  @media (max-width: ${theme.maxWidthSm}) {
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11 {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
`
export default grid
